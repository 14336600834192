.scroll-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.scroll {
  background-color: #f8f4e5; /* Parchment-like background color */
  padding: 20px;
  overflow-y: scroll;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc; /* Optional: Add border for parchment effect */
}

.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.center-image {
  width: 50px; /* Adjust image size as needed */
  height: auto;
  margin-top: 20px; /* Adjust spacing between text and image */
}
